import theGetters from './getters';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: { SUPPORT_VIDEO_CODEC: [] },
}));

describe('getter', () => {
  describe('isSearching', () => {
    it('should return true if searching', () => {
      const state = {
        status: 'loading'
      };
      expect(theGetters.isSearching(state)).toBe(true);
    });
    it('should return false if initial', () => {
      const state = {
        status: 'initial'
      };
      expect(theGetters.isSearching(state)).toBe(false);
    });
  });
  describe('hasSearchedResult', () => {
    it('should return true if result is more than one item', () => {
      const state = {
        currentType: 'smartSearchII',
        smartSearchII: {
          result: [{}]
        }
      };

      expect(theGetters.hasSearchedResult(state)).toBe(true);
    });
    it('should return false if result is no item', () => {
      const state = {
        currentType: 'smartSearchII',
        smartSearchII: {
          result: []
        }
      };

      expect(theGetters.hasSearchedResult(state)).toBe(false);
    });
  });
});
